/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { JSONSupport as o } from "../core/JSONSupport.js";
import { toPt as e } from "../core/screenUtils.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import { cast as r } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { fontDecorations as p, fontStyles as a, fontWeights as n } from "./support/textUtils.js";
var c;
let l = c = class extends o {
  constructor(t) {
    super(t), this.decoration = "none", this.family = "sans-serif", this.size = 9, this.style = "normal", this.weight = "normal";
  }
  castSize(t) {
    return e(t);
  }
  clone() {
    return new c({
      decoration: this.decoration,
      family: this.family,
      size: this.size,
      style: this.style,
      weight: this.weight
    });
  }
  hash() {
    return `${this.decoration}.${this.family}.${this.size}.${this.style}.${this.weight}`;
  }
};
t([s({
  type: p,
  json: {
    default: "none",
    write: !0
  }
})], l.prototype, "decoration", void 0), t([s({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "family", void 0), t([s({
  type: Number,
  json: {
    write: {
      overridePolicy: (t, o, e) => ({
        enabled: !e || !e.textSymbol3D
      })
    }
  }
})], l.prototype, "size", void 0), t([r("size")], l.prototype, "castSize", null), t([s({
  type: a,
  json: {
    default: "normal",
    write: !0
  }
})], l.prototype, "style", void 0), t([s({
  type: n,
  json: {
    default: "normal",
    write: !0
  }
})], l.prototype, "weight", void 0), l = c = t([i("esri.symbols.Font")], l);
const m = l;
export { m as default };