/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import o from "../Color.js";
import { clone as e } from "../core/lang.js";
import { toPt as i } from "../core/screenUtils.js";
import { property as r } from "../core/accessorSupport/decorators/property.js";
import { cast as s } from "../core/accessorSupport/decorators/cast.js";
import { enumeration as n } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as h } from "../core/accessorSupport/decorators/subclass.js";
import { writer as l } from "../core/accessorSupport/decorators/writer.js";
import p from "./Font.js";
import a from "./Symbol.js";
import { horizontalAlignmentProperty as d, verticalAlignmentProperty as c, lineHeightProperty as y } from "./support/textUtils.js";
var f;
let g = f = class extends a {
  constructor(...t) {
    super(...t), this.backgroundColor = null, this.borderLineColor = null, this.borderLineSize = null, this.font = new p(), this.horizontalAlignment = "center", this.kerning = !0, this.haloColor = null, this.haloSize = null, this.rightToLeft = null, this.rotated = !1, this.text = "", this.type = "text", this.verticalAlignment = "baseline", this.xoffset = 0, this.yoffset = 0, this.angle = 0, this.width = null, this.lineWidth = 192, this.lineHeight = 1;
  }
  normalizeCtorArgs(t, o, e) {
    if (t && "string" != typeof t) return t;
    const i = {};
    return t && (i.text = t), o && (i.font = o), e && (i.color = e), i;
  }
  writeLineWidth(t, o, e, i) {
    i && "string" != typeof i ? i.origin : o[e] = t;
  }
  castLineWidth(t) {
    return i(t);
  }
  writeLineHeight(t, o, e, i) {
    i && "string" != typeof i ? i.origin : o[e] = t;
  }
  clone() {
    return new f({
      angle: this.angle,
      backgroundColor: e(this.backgroundColor),
      borderLineColor: e(this.borderLineColor),
      borderLineSize: this.borderLineSize,
      color: e(this.color),
      font: this.font && this.font.clone(),
      haloColor: e(this.haloColor),
      haloSize: this.haloSize,
      horizontalAlignment: this.horizontalAlignment,
      kerning: this.kerning,
      lineHeight: this.lineHeight,
      lineWidth: this.lineWidth,
      rightToLeft: this.rightToLeft,
      rotated: this.rotated,
      text: this.text,
      verticalAlignment: this.verticalAlignment,
      width: this.width,
      xoffset: this.xoffset,
      yoffset: this.yoffset
    });
  }
  hash() {
    return `${this.backgroundColor?.hash()}.${this.borderLineColor}.${this.borderLineSize}.${this.color?.hash()}.${this.font && this.font.hash()}.${this.haloColor?.hash()}.${this.haloSize}.${this.horizontalAlignment}.${this.kerning}.${this.rightToLeft}.${this.rotated}.${this.text}.${this.verticalAlignment}.${this.width}.${this.xoffset}.${this.yoffset}.${this.lineHeight}.${this.lineWidth}.${this.angle}`;
  }
};
t([r({
  type: o,
  json: {
    write: !0
  }
})], g.prototype, "backgroundColor", void 0), t([r({
  type: o,
  json: {
    write: !0
  }
})], g.prototype, "borderLineColor", void 0), t([r({
  type: Number,
  json: {
    write: !0
  },
  cast: i
})], g.prototype, "borderLineSize", void 0), t([r({
  type: p,
  json: {
    write: !0
  }
})], g.prototype, "font", void 0), t([r({
  ...d,
  json: {
    write: !0
  }
})], g.prototype, "horizontalAlignment", void 0), t([r({
  type: Boolean,
  json: {
    write: !0
  }
})], g.prototype, "kerning", void 0), t([r({
  type: o,
  json: {
    write: !0
  }
})], g.prototype, "haloColor", void 0), t([r({
  type: Number,
  cast: i,
  json: {
    write: !0
  }
})], g.prototype, "haloSize", void 0), t([r({
  type: Boolean,
  json: {
    write: !0
  }
})], g.prototype, "rightToLeft", void 0), t([r({
  type: Boolean,
  json: {
    write: !0
  }
})], g.prototype, "rotated", void 0), t([r({
  type: String,
  json: {
    write: !0
  }
})], g.prototype, "text", void 0), t([n({
  esriTS: "text"
}, {
  readOnly: !0
})], g.prototype, "type", void 0), t([r({
  ...c,
  json: {
    write: !0
  }
})], g.prototype, "verticalAlignment", void 0), t([r({
  type: Number,
  cast: i,
  json: {
    write: !0
  }
})], g.prototype, "xoffset", void 0), t([r({
  type: Number,
  cast: i,
  json: {
    write: !0
  }
})], g.prototype, "yoffset", void 0), t([r({
  type: Number,
  json: {
    read: t => t && -1 * t,
    write: (t, o) => o.angle = t && -1 * t
  }
})], g.prototype, "angle", void 0), t([r({
  type: Number,
  json: {
    write: !0
  }
})], g.prototype, "width", void 0), t([r({
  type: Number
})], g.prototype, "lineWidth", void 0), t([l("lineWidth")], g.prototype, "writeLineWidth", null), t([s("lineWidth")], g.prototype, "castLineWidth", null), t([r(y)], g.prototype, "lineHeight", void 0), t([l("lineHeight")], g.prototype, "writeLineHeight", null), g = f = t([h("esri.symbols.TextSymbol")], g);
const m = g;
export { m as default };