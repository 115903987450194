/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { toPt as e } from "../core/screenUtils.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import r from "./Symbol.js";
let p = class extends r {
  constructor(t) {
    super(t), this.angle = 0, this.type = null, this.xoffset = 0, this.yoffset = 0, this.size = 9;
  }
  hash() {
    return `${this.type}.${this.angle}.${this.size}.${this.xoffset}.${this.yoffset}`;
  }
};
t([o({
  type: Number,
  json: {
    read: t => t && -1 * t,
    write: (t, e) => e.angle = t && -1 * t
  }
})], p.prototype, "angle", void 0), t([o({
  type: ["simple-marker", "picture-marker"],
  readOnly: !0
})], p.prototype, "type", void 0), t([o({
  type: Number,
  cast: e,
  json: {
    write: !0
  }
})], p.prototype, "xoffset", void 0), t([o({
  type: Number,
  cast: e,
  json: {
    write: !0
  }
})], p.prototype, "yoffset", void 0), t([o({
  type: Number,
  cast: t => "auto" === t ? t : e(t),
  json: {
    write: !0
  }
})], p.prototype, "size", void 0), p = t([s("esri.symbols.MarkerSymbol")], p);
const i = p;
export { i as default };