/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import r from "../Color.js";
import { JSONMap as e } from "../core/jsonMap.js";
import { JSONSupport as s } from "../core/JSONSupport.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { reader as l } from "../core/accessorSupport/decorators/reader.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
const p = new e({
  esriSMS: "simple-marker",
  esriPMS: "picture-marker",
  esriSLS: "simple-line",
  esriSFS: "simple-fill",
  esriPFS: "picture-fill",
  esriTS: "text",
  esriSHD: "shield-label-symbol",
  PointSymbol3D: "point-3d",
  LineSymbol3D: "line-3d",
  PolygonSymbol3D: "polygon-3d",
  WebStyleSymbol: "web-style",
  MeshSymbol3D: "mesh-3d",
  LabelSymbol3D: "label-3d",
  CIMSymbolReference: "cim"
});
let m = 0,
  c = class extends s {
    constructor(o) {
      super(o), this.id = "sym" + m++, this.type = null, this.color = new r([0, 0, 0, 1]);
    }
    readColor(o) {
      return null != o?.[0] ? [o[0], o[1], o[2], o[3] / 255] : o;
    }
    async collectRequiredFields(o, r) {}
    hash() {
      return JSON.stringify(this.toJSON());
    }
    clone() {}
  };
o([t({
  type: p.apiValues,
  readOnly: !0,
  json: {
    read: !1,
    write: {
      ignoreOrigin: !0,
      writer: p.write
    }
  }
})], c.prototype, "type", void 0), o([t({
  type: r,
  json: {
    write: {
      allowNull: !0
    }
  }
})], c.prototype, "color", void 0), o([l("color")], c.prototype, "readColor", null), c = o([i("esri.symbols.Symbol")], c);
const n = c;
export { n as default };